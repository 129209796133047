@keyframes growDown {
    0% {
         transform: scaleY(0) 
    }
    100% {
         transform: scaleY(1) 
    }
}
@keyframes growUp {
    0% {
         transform: scaleY(1) 
    }
    100% {
         transform: scaleY(0) 
    }
}
.menu-primary-appear{
    oppacity: 0;

}
.menu-primary-appear.menu-primary-appear-active{
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
}
.menu-primary-exit{
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
}
.menu-primary-exit.menu-primary-exit-active{
    animation: growUp 300ms ease-in-out forwards;
    transform-origin: top center;
}
.menu-primary-exit-done{
    animation: growUp 300ms ease-in-out forwards;
    transform-origin: top center;
}
.menu-primary-enter{
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
}
.menu-primary-enter.menu-primary-enter-active{
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
}
#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 18px;
}

#nav-icon3 span:nth-child(4) {
  top: 36px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
